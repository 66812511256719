<template>
    <gmap-map
        ref="maps"
        :center="{ lat:Number(coords.map_lat), lng:Number(coords.map_lng) }"
        :zoom="zoom"
        map-type-id="terrain"
        :style="`height: ${height}px`"
        :options="{
            scrollwheel: false
        }"
    >
        <gmap-marker
            :key="10"
            :position="{ lat:Number(coords.map_lat), lng:Number(coords.map_lng)}"
            :clickable="false"
            :draggable="markerDraggable"
            @dragend="markerDragChanged"
        />
        <gmap-circle
            v-if="geofenceRadius"
            :center="{lat:Number(coords.map_lat), lng:Number(coords.map_lng)}"
            :radius="geofenceRadius"
            :options="{
                fillColor: '#77b4ff',
                fillOpacity: 0.2,
                strokeColor: '#7eb4ff',
                strokeWeight: 1,
                clickable: false
            }"
        />
    </gmap-map>
</template>
<script>
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
    installComponents: true,
    load:              {
        key:       'AIzaSyCNIRpGZdH8CTBizW-HcGlAhnO_8YHvRNg',
        libraries: 'places,geometry',
        language:  'se',
    },
});
export default {
    props: {
        zoom: {
            type:    Number,
            default: 14,
        },
        height: {
            type:    Number,
            default: 300,
        },
        markerDraggable: {
            type:    Boolean,
            default: false,
        },
        geofenceRadius: {
            type:    Number,
            default: 0,
        },
        coords: {
            type:    Object,
            default: () => ({
                map_lat: 59.3260664,
                map_lng: 17.8471233,
            }),
        },
    },

    methods: {
        markerDragChanged(event) {
            this.$emit('markerDragged', {
                mapLat: event.latLng.lat(),
                mapLng: event.latLng.lng(),
            });
        },
    },
};
</script>
