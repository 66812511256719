var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gmap-map',{ref:"maps",style:(("height: " + _vm.height + "px")),attrs:{"center":{ lat:Number(_vm.coords.map_lat), lng:Number(_vm.coords.map_lng) },"zoom":_vm.zoom,"map-type-id":"terrain","options":{
        scrollwheel: false
    }}},[_c('gmap-marker',{key:10,attrs:{"position":{ lat:Number(_vm.coords.map_lat), lng:Number(_vm.coords.map_lng)},"clickable":false,"draggable":_vm.markerDraggable},on:{"dragend":_vm.markerDragChanged}}),(_vm.geofenceRadius)?_c('gmap-circle',{attrs:{"center":{lat:Number(_vm.coords.map_lat), lng:Number(_vm.coords.map_lng)},"radius":_vm.geofenceRadius,"options":{
            fillColor: '#77b4ff',
            fillOpacity: 0.2,
            strokeColor: '#7eb4ff',
            strokeWeight: 1,
            clickable: false
        }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }